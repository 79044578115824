<template>
  <section class="tech-stack-selection">
    <div class="tech-stack-selection__content constrain-content center">
      <h2 class="margin-bottom--large">What <span class="text-color--blue">tech stack</span> do <br/>you want to work with?</h2>
      <ul v-if="tech_stacks">
        <li v-for="item in tech_stacks" :key="item.title" @click="EbbaModal({ui_tech_stack_selection:item.title})" class="cursor--pointer" :class="item.class">
          <img :src="item.icon" />
          <span>{{item.title}}</span>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import ModalMixin from '@/mixins/modal'
import tech_stacks from '@/assets/misc/tech-stacks.js'

export default {
  mixins: [ModalMixin],
  data() {
    return {
      tech_stacks
    };
  },
  mounted() {

  },
};
</script>
<style lang="scss">
.tech-stack-selection {
  background-color: #F7F7F7;
  min-height: 500px;
  display: flex;
  align-items: center;
  h2 {

  }
  ul {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);

      @include sm {
        grid-template-columns: repeat(2, 1fr)
      }
      @include md {
        grid-template-columns: repeat(3, 1fr)
      }
      @include lg {
        grid-template-columns: repeat(3, 1fr)
      }

      li {
        user-select: none;
        display: flex;
        padding: var(--padding-small);
        padding-left: calc(.8 * var(--padding-small));
        border-radius: var(--border-radius-medium);
        box-shadow: 2px 2px 8px rgb(0, 0, 0, 9%);
        background-color: #ffffff;

        img {
          max-width: 25px;
        }
        span {
            transition: all .25s ease;
            font-size: 1.1rem;
            padding-left: .9rem;
            font-weight: 600;
            text-align: left;
            text-align: left;
            align-self: center;
            white-space: nowrap;
            @include sm {
            padding-right: .65rem;
            padding-left: 1.9rem;
            }
        }
        &.go {
            img {
                max-width: 32px;
            }
            span {
                padding-left: 19px;
            }
        }
        &:hover {
          span {
            transform: translateX(.2125rem);
          }
          img {}
        }


      }
  }
}

</style>
