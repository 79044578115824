const tech_stack_icons = {
    dotnet: require('@/assets/ico-black-white/windows.svg'),
    ios: require('@/assets/ico-black-white/apple.svg'),
    java: require('@/assets/ico-black-white/java.svg'),
    backend: require('@/assets/ico-black-white/backend.svg'),
    react: require('@/assets/ico-black-white/react.svg'),
    devops: require('@/assets/ico-black-white/devops.svg'),
    wordpress: require('@/assets/ico-black-white/wordpress.svg'),
    go: require('@/assets/ico-black-white/go.svg'),
    php: require('@/assets/ico-black-white/php.svg'),
}
export default [
    {
        title: 'Java',
        icon: tech_stack_icons.java,
        class: 'java',
    },
    {
        title: '.NET',
        icon: tech_stack_icons.dotnet,
        class: 'dotnet',
    },
    {
        title: 'Golang',
        icon: tech_stack_icons.go,
        class: 'go',
    },
    {
        title: 'iOS/Android',
        icon: tech_stack_icons.ios,
        class: 'ios-android',
    },
    {
        title: 'PHP',
        icon: tech_stack_icons.php,
        class: 'php',
    },
    {
        title: 'C/C++',
        icon: tech_stack_icons.backend,
        class: 'c-plus-plus',
    },
    {
        title: 'React/Vue',
        icon: tech_stack_icons.react,
        class: 'react-vue',
    },
    {
        title: 'Devops',
        icon: tech_stack_icons.devops,
        class: 'devops',
    },
]


